import { bool, envsafe, str } from 'envsafe';
import { ConfigType } from 'providers';

export const browserEnv = envsafe(
  {
    NEXT_PUBLIC_APP_CODE: str({
      devDefault: 'TBA-WEB',
      input: process.env.NEXT_PUBLIC_APP_CODE,
    }),
    NEXT_PUBLIC_CMS_API_HOST_URL: str({
      devDefault: 'https://cms.beta.marinedatacloud.com',
      input: process.env.NEXT_PUBLIC_CMS_API_HOST_URL,
    }),
    NEXT_PUBLIC_GA_ID: str({
      input: process.env.NEXT_PUBLIC_GA_ID,
      allowEmpty: true,
    }),
    NEXT_PUBLIC_MDC_API_HOST_URL: str({
      devDefault: 'https://api.beta.marinedatacloud.com',
      input: process.env.NEXT_PUBLIC_MDC_API_HOST_URL,
    }),
    NEXT_PUBLIC_PASSWORD_PROTECT: bool({
      input: process.env.NEXT_PUBLIC_PASSWORD_PROTECT,
    }),
    NEXT_PUBLIC_SENTRY_DSN: str({
      input: process.env.NEXT_PUBLIC_SENTRY_DSN,
    }),
    NEXT_PUBLIC_SENTRY_ENV: str({
      input: process.env.NEXT_PUBLIC_SENTRY_ENV,
    }),
    NEXT_PUBLIC_SENTRY_ENABLED: bool({
      input: process.env.NEXT_PUBLIC_SENTRY_ENABLED,
    }),
    NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE: str({
      input: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    }),
  },
  {
    // As we're using plain JS, it's useful to use strict mode which prevents accessing undefined props
    strict: true,
  },
);

export function getAppConfiguration(): ConfigType {
  return {
    appCode: browserEnv.NEXT_PUBLIC_APP_CODE,
    googleAnalyticId: browserEnv.NEXT_PUBLIC_GA_ID,
    sentryDns: browserEnv.NEXT_PUBLIC_SENTRY_DSN,
    sentryEnv: browserEnv.NEXT_PUBLIC_SENTRY_ENV,
    sentryEnabled: browserEnv.NEXT_PUBLIC_SENTRY_ENABLED,
    sentryTracesSampleRate: browserEnv.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE,
    mdcApiHost: browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL,
    mdcFullApiUrl: `${browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL}/api/v2/`,
    mdcGraphQlUrl: `${browserEnv.NEXT_PUBLIC_MDC_API_HOST_URL}/graphql`,
    cmsFullApiUrl: `${browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL}/api`,
    cmsGraphQlUrl: `${browserEnv.NEXT_PUBLIC_CMS_API_HOST_URL}/graphql`,
  };
}
